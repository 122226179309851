import angular from 'angular';
import { getBaseActionUrl } from '../utils/route';
import { factory, directive } from './app';
import { ACTION as LEI_ACTION } from '../../../common/const/lei-products';

var widthFactor = function(w) {
  return Math.floor(w * 0.86);
};

function ensureDisplayName(item) {
  if (!item.displayName) {
    item.displayName = this('humanize')(item.field);
  }
  return item;
}

function getFieldsFromColumnDefs(columnDefs) {
  var fields = {};
  for (var i = 0, l = columnDefs.length; i < l; i++) {
    fields[(columnDefs[i] || {}).field] = 1;
  }
  return Object.keys(fields);
}

// exposes named arguments meta my submission
factory('RequestedLeiColumnDefs', [
  'uiGridConstants',
  'DefaultUIGridTooltip',
  '$filter',
  function(uiGridConstants, DefaultUIGridTooltip, $filter) {
    return [
      {
        field: 'humanReadableWorkId',
        displayName: 'Tracking #',
        cellTemplate:
          '<div class="ui-grid-cell-contents" ><a href=\'{{(row.entity.type == "CHALLENGE" ? "/leis/leiChallenge/" : "/users/submissions/") + row.entity.workId + "/" + row.entity.humanReadableWorkId}}\'>{{row.entity.humanReadableWorkId}}</a></div>',
        minWidth: widthFactor(135)
      },
      {
        field: 'submissionTime',
        type: 'date',
        cellFilter: "date:'yyyy-MM-dd'",
        displayName: 'Submission Time',
        minWidth: widthFactor(100),
        sort: { direction: 'desc', priority: 0 },
        sortDirectionCycle: [null, uiGridConstants.DESC, uiGridConstants.ASC]
      },
      {
        field: 'type',
        cellTemplate:
          '<div class="ui-grid-cell-contents">{{ ( row.entity.type || "" ) | lowercase | humanize | actionTypeDisplay }}</div>',
        minWidth: widthFactor(100)
      },
      { field: 'finalId', displayName: 'LEI', minWidth: widthFactor(200) },
      { field: 'legalName', displayName: 'Legal Name', minWidth: widthFactor(200) },
      { field: 'email', minWidth: widthFactor(125) },
      {
        field: '_paymentAmount',
        displayName: 'Base Price',
        enableSorting: false,
        cellTemplate: '<div class="ui-grid-cell-contents text-right">{{ row.entity.paymentAmountFormatted }}</div>',
        minWidth: widthFactor(75)
      },
      {
        field: 'invoice',
        displayName: 'Invoice',
        enableSorting: false,
        cellTemplate: '<div request-submission-invoice row="row" class="ui-grid-cell-contents text-center"></div>',
        minWidth: widthFactor(50)
      },
      {
        field: 'status',
        displayName: 'Status',
        cellTemplate:
          '<div class="ui-grid-cell-contents">{{ ( row.entity.status || "" ) | lowercase | humanize }}</div>',
        minWidth: widthFactor(150)
      },
      {
        field: '_actions',
        displayName: 'Actions',
        enableSorting: false,
        cellTemplate: '<div requested-lei-actions row="row" class="ui-grid-cell-contents"></div>',
        minWidth: widthFactor(100)
      }
    ]
      .map(ensureDisplayName.bind($filter))
      .map(DefaultUIGridTooltip);
  }
]);

directive('requestSubmissionInvoice', [
  function() {
    return {
      scope: { row: '=' },
      template:
        '<a ng-if="isPresent()" download="{{downloadName()}}" href="{{getUrl()}}">' +
        '<i class="fa fa-download fa-lg" aria-hidden="true"></i>' +
        '</a>',
      link: function(scope, element, attrs) {
        scope.isPresent = function() {
          const invoice = scope.row.entity.invoiceUri;
          return invoice && invoice.length > 0;
        };
        scope.downloadName = function() {
          return 'invoice-' + scope.row.entity.humanReadableWorkId;
        };
        scope.getUrl = function() {
          return scope.row.entity.invoiceUri;
        };
      }
    };
  }
]);

directive('requestedLeiActions', [
  function() {
    return {
      scope: { row: '=' },
      template: '<a ng-if="isValid()" href="{{getUrl()}}">Clone</a>',
      link: function(scope, element, attrs) {
        scope.isValid = function() {
          return (
            [
              LEI_ACTION.NEW,
              LEI_ACTION.RENEW,
              LEI_ACTION.UPDATE,
              LEI_ACTION.TRANSFER,
              LEI_ACTION.TRANSFERRENEW
            ].indexOf(scope.row.entity.type) !== -1
          );
        };
        scope.getUrl = function() {
          return getBaseActionUrl(scope.row.entity.type, scope.row.entity.finalId) + '?fill=' + scope.row.entity.workId;
        };
      }
    };
  }
]);

factory('RequestedLeiPaginationService', [
  'UserService',
  function(UserService) {
    var inclusive = [
      'likeHumanReadableWorkId',
      'likeEmail',
      'likeFinalId',
      'likeSubmissionTime',
      'likePaymentAmount',
      'likeType',
      'likeStatus',
      'likeLegalName'
    ];
    return {
      getPage: function(mode, offset, limit, sortDef, textFilter, numFilter, namedFilters) {
        return UserService.getSubmissions(
          offset,
          limit,
          sortDef,
          inclusive,
          textFilter,
          textFilter,
          textFilter,
          textFilter,
          textFilter,
          numFilter,
          textFilter,
          namedFilters
        );
      }
    };
  }
]);

// autorenew LEI tab
factory('AutoRenewLEIColumnDefs', [
  'uiGridConstants',
  'DefaultUIGridTooltip',
  '$filter',
  function(uiGridConstants, DefaultUIGridTooltip, $filter) {
    return [
      { field: 'key', displayName: 'LEI', minWidth: widthFactor(200) },
      { field: 'legalName', displayName: 'Legal Name', minWidth: widthFactor(200) },
      {
        field: 'renewDate',
        type: 'date',
        cellFilter: "date:'yyyy-MM-dd'",
        displayName: 'Auto Renew Date',
        minWidth: widthFactor(100),
        enableSorting: false
      },
      {
        field: 'paymentType',
        minWidth: widthFactor(125),
        cellTemplate:
          '<div class="ui-grid-cell-contents">{{(row.entity.card || row.entity.paymentType) | paymentTypeDisplay }}</div>',
        enableSorting: false
      },
      {
        field: 'registrationStatus',
        displayName: 'Registration Status',
        cellTemplate:
          '<div class="ui-grid-cell-contents">{{ ( row.entity.registrationStatus || "" ) | lowercase | humanize}}</a></div>',
        minWidth: widthFactor(85)
      },
      {
        field: 'nextRenewalDate',
        type: 'date',
        cellFilter: "date:'yyyy-MM-dd'",
        displayName: 'Next Renewal Date',
        minWidth: widthFactor(100),
        sort: { direction: 'asc', priority: 0 }
      },
      {
        field: 'lastModifiedDate',
        type: 'date',
        cellFilter: "date:'yyyy-MM-dd'",
        displayName: 'Last Modified Date',
        minWidth: widthFactor(100),
        enableSorting: false
      },
      {
        field: '_bulk',
        displayName: 'Select',
        enableSorting: false,
        cellTemplate:
          '<div class="ui-grid-cell-contents text-center">' +
          '<a ng-click="grid.appScope.exportMyLeis[row.entity.key] = !grid.appScope.exportMyLeis[row.entity.key]" nohref><i class="fa fa-square-o" ng-class="grid.appScope.exportMyLeis[row.entity.key]?\'fa-check-square-o\':\'fa-square-o\'" aria-hidden="true"></i></a>' +
          '</div>',
        width: 144
      }
    ]
      .map(ensureDisplayName.bind($filter))
      .map(DefaultUIGridTooltip);
  }
]);

directive('autoRenewLeiActions', [
  'UserService',
  'ServiceErrorHandler',
  function(UserService, ServiceErrorHandler) {
    return {
      scope: { row: '=' },
      template: '<a href="#" ng-click="onClick()" >Cancel</a>',
      link: function(scope, element, attrs) {
        scope.onClick = function() {
          return UserService.deleteAutoRenew(scope.row.entity.lei).then(function() {
            window.location.reload();
          }, ServiceErrorHandler({ alert: 'data' }));
        };
      }
    };
  }
]);

factory('AutoRenewPaginationService', [
  'UserService',
  'ServiceErrorHandler',
  'CardService',
  function(UserService, ServiceErrorHandler, CardService) {
    let cards;
    const getCards = async () => {
      if (!cards) {
        const cardsInfo = (await CardService.getCards()) || [];
        cards = cardsInfo.data.data.reduce((accum, card) => {
          const cardInfo = (card.brand || card.bank_name) + ' end with ' + card.last4;
          accum[card.id] = cardInfo;
          return accum;
        }, {});
        return cards;
      }
      return cards;
    };

    return {
      getPage: function(mode, offset, limit, sortDef, textFilter, numFilter, namedFilters) {
        return getCards()
          .then(() => UserService.getAutoRenew(offset, limit, sortDef, textFilter))
          .then(function(res) {
            var headers = res.headers ? res.headers() : {};
            var leis = [];
            if (res && res.data && angular.isArray(res.data)) {
              res.data.forEach(function(item) {
                var lei = {};
                var json = item;
                lei.key = json.key.lei;
                lei.lei = json.key.lei;
                lei.email = (json.renewal || {}).email;
                lei.paymentType = (json.renewal || {}).paymentType;
                lei.card = cards[(json.renewal || {}).paymentMethodId];
                lei.legalName = json.data.entity.legalName;
                lei.submissionTime = (json.renewal || {}).submissionTime;
                if (json.renewal && json.renewal.renewDate) {
                  var today = new Date();
                  var toyear = new Date().getFullYear();
                  var renewaldate = new Date(json.renewal.renewDate);
                  renewaldate.setYear(toyear);
                  if (today > renewaldate) renewaldate.setYear(toyear + 1);
                  lei.renewDate = renewaldate;
                }
                lei.registrationStatus = json.data.registration.registrationStatus;
                lei.nextRenewalDate = json.data.registration.nextRenewalDate;
                leis.push(lei);
              });
            }

            return { data: leis, startOffset: headers['x-start-offset'], totalResults: headers['x-total-results'] };
          })
          .catch(ServiceErrorHandler({ alert: 'data' }));
      }
    };
  }
]);

// drafts tab
factory('DraftColumnDefs', [
  'uiGridConstants',
  'DefaultUIGridTooltip',
  '$filter',
  function(uiGridConstants, DefaultUIGridTooltip, $filter) {
    return [
      {
        field: 'submissionTime',
        type: 'date',
        cellFilter: "date:'yyyy-MM-dd HH:mm'",
        displayName: 'Saved Time',
        minWidth: widthFactor(100),
        sort: { direction: 'desc', priority: 0 },
        sortDirectionCycle: [null, uiGridConstants.DESC, uiGridConstants.ASC]
      },
      {
        field: 'type',
        cellTemplate:
          '<div class="ui-grid-cell-contents">{{ ( row.entity.type || "" ) | lowercase | humanize | actionTypeDisplay }}</div>',
        minWidth: widthFactor(100)
      },
      { field: 'finalId', displayName: 'LEI', minWidth: widthFactor(200) },
      { field: 'legalName', displayName: 'Legal Name', minWidth: widthFactor(200) },
      { field: 'email', minWidth: widthFactor(125) },
      {
        field: '_actions',
        displayName: 'Actions',
        enableSorting: false,
        cellTemplate: '<div draft-lei-actions row="row" class="ui-grid-cell-contents"></div>',
        minWidth: widthFactor(100)
      }
    ]
      .map(ensureDisplayName.bind($filter))
      .map(DefaultUIGridTooltip);
  }
]);

directive('draftLeiActions', [
  function() {
    return {
      scope: { row: '=' },
      template: '<a ng-if="isValid()" href="{{getUrl()}}">Load</a>',
      link: function(scope, element, attrs) {
        scope.isValid = function() {
          return (
            [
              LEI_ACTION.NEW,
              LEI_ACTION.RENEW,
              LEI_ACTION.UPDATE,
              LEI_ACTION.TRANSFER,
              LEI_ACTION.TRANSFERRENEW,
              LEI_ACTION.CHALLENGE
            ].indexOf(scope.row.entity.type) !== -1
          );
        };
        scope.getUrl = function() {
          return (
            getBaseActionUrl(scope.row.entity.type, scope.row.entity.finalId) +
            '?loadDraft=' +
            scope.row.entity.keyForAction
          );
        };
      }
    };
  }
]);

factory('DraftPaginationService', [
  'DraftService',
  'ServiceErrorHandler',
  function(DraftService, ServiceErrorHandler) {
    return {
      getPage: function(mode, offset, limit, sortDef, textFilter, numFilter, namedFilters) {
        return DraftService.getDraftSubmissions(offset, limit, sortDef).catch(ServiceErrorHandler({ alert: 'data' }));
      }
    };
  }
]);

// my leis tab
factory('IssuedLeiColumnDefs', [
  'uiGridConstants',
  'DefaultUIGridTooltip',
  '$filter',
  function(uiGridConstants, DefaultUIGridTooltip, $filter) {
    return [
      {
        field: 'key',
        displayName: 'LEI',
        cellTemplate:
          '<div class="ui-grid-cell-contents"> \
            <a ng-if="grid.appScope.submissionType" href="{{grid.appScope.action.getLeiLink(row.entity)}}">{{row.entity.key}}</a> \
            <a ng-if="!grid.appScope.submissionType" href="{{grid.appScope.action.getLeiLink(row.entity)}}" ng-click="$event.preventDefault(); grid.appScope.action.redirect(row.entity)">{{row.entity.key}}</a> \
          </div>',
        minWidth: widthFactor(200),
        width: 245
      },
      {
        field: 'legalName',
        displayName: 'Legal Name',
        sort: { direction: 'desc', priority: 0 },
        minWidth: widthFactor(200)
      },
      { field: 'legalNameInEnglish', displayName: 'Legal Name In English', minWidth: widthFactor(190) },
      {
        field: 'registrationStatus',
        displayName: 'Registration Status',
        cellTemplate:
          '<div class="ui-grid-cell-contents">{{ ( row.entity.registrationStatus || "" ) | lowercase | humanize}}</a></div>',
        minWidth: widthFactor(85),
        width: 180
      },
      {
        field: 'initialRegistrationDate',
        type: 'date',
        displayName: 'Registration Date',
        minWidth: widthFactor(100),
        width: 200
      },
      {
        field: 'lastUpdateDate',
        type: 'date',
        displayName: 'Last Update Date',
        minWidth: widthFactor(100),
        width: 200
      },
      {
        field: 'nextRenewalDate',
        type: 'date',
        displayName: 'Next Renewal Date',
        minWidth: widthFactor(100),
        width: 200
      },
      {
        field: '_actions',
        displayName: 'Item Actions',
        enableSorting: false,
        cellTemplate:
          '<div class="ui-grid-cell-contents">' +
          '<a href="/leis/update/{{row.entity.key}}">Update</a>, ' +
          '<a href="/leis/renew/{{row.entity.key}}">Renew</a>, ' +
          '<a target="_blank" href="/lei/exportExcel/{{row.entity.key}}">Download</a>' +
          '</div>',
        minWidth: widthFactor(300),
        width: 220
      },
      {
        field: '_bulk',
        displayName: 'Select',
        enableSorting: false,
        cellTemplate:
          '<div class="ui-grid-cell-contents text-center">' +
          '<a ng-click="grid.appScope.exportMyLeis[row.entity.key] = !grid.appScope.exportMyLeis[row.entity.key]" nohref><i class="fa fa-square-o" ng-class="grid.appScope.exportMyLeis[row.entity.key]?\'fa-check-square-o\':\'fa-square-o\'" aria-hidden="true"></i></a>' +
          '</div>',
        width: 110
      }
    ]
      .map(ensureDisplayName.bind($filter))
      .map(DefaultUIGridTooltip);
  }
]);

factory('IssuedLeiPaginationService', [
  'IssuedLeiColumnDefs',
  'UserService',
  'FriendlyIsoTime',
  'ServiceErrorHandler',
  function(ColumnDefs, UserService, friendlyIsoTime, ServiceErrorHandler) {
    var fields = {};
    for (var i = 0, l = ColumnDefs.length; i < l; i++) fields[(ColumnDefs[i] || {}).field] = 1;
    fields = Object.keys(fields);
    return {
      getPage: function(mode, offset, limit, sortDef, textFilter, numFilter, namedFilters) {
        return UserService.getLeis(offset, limit, sortDef, fields, textFilter, numFilter, namedFilters).then(function(
          res
        ) {
          var headers = res.headers ? res.headers() : {};
          var leis = [];
          if (res && res.data && angular.isArray(res.data))
            res.data.forEach(function(json) {
              var lei = {};
              lei.key = json.key.lei;
              lei.legalName = json.data.entity.legalName;
              lei.legalNameInEnglish = json.data.entity.legalNameInEnglish;
              lei.registrationStatus = json.data.registration.registrationStatus;
              lei.initialRegistrationDate = friendlyIsoTime(json.data.registration.initialRegistrationDate);
              lei.lastUpdateDate = friendlyIsoTime(json.data.registration.lastUpdateDate);
              lei.nextRenewalDate = friendlyIsoTime(json.data.registration.nextRenewalDate);

              leis.push(lei);
            });

          return {
            data: leis,
            startOffset: headers['x-start-offset'],
            totalResults: headers['x-total-results']
          };
        },
        ServiceErrorHandler({ alert: 'data' }));
      }
    };
  }
]);

// common to both lei and gleif
factory('SearchLeiAllColumns', [
  'uiGridConstants',
  function(uiGridConstants) {
    var colDefs = {};
    colDefs.LEI = {
      field: 'key',
      displayName: 'LEI',
      cellTemplate:
        '<div class="ui-grid-cell-contents"> \
          <a ng-if="grid.appScope.submissionType" href="{{grid.appScope.action.getLeiLink(row.entity)}}">{{row.entity.key}}</a> \
          <a ng-if="!grid.appScope.submissionType" href="{{grid.appScope.action.getLeiLink(row.entity)}}" ng-click="$event.preventDefault(); grid.appScope.action.redirect(row.entity)">{{row.entity.key}}</a> \
        </div>',
      minWidth: widthFactor(200),
      width: 245
    };
    colDefs.legalName = { field: 'legalName', minWidth: widthFactor(320) };
    colDefs.legalNameInEnglish = { field: 'legalNameInEnglish', minWidth: widthFactor(320) };
    colDefs.registeredCountry = {
      field: 'registeredCountry',
      displayName: 'Country',
      cellTemplate:
        '<div class="ui-grid-cell-contents" title="{{row.entity.registeredCountry.value}}">{{row.entity.registeredCountry.key}}</div>',
      minWidth: widthFactor(100)
    };
    colDefs.registrationStatus = {
      field: 'registrationStatus',
      displayName: 'Registration Status',
      cellTemplate:
        '<div class="ui-grid-cell-contents">{{ ( row.entity.registrationStatus || "" ) | lowercase | humanize}}</a></div>',
      minWidth: widthFactor(175)
    };
    colDefs.entityStatus = {
      field: 'entityStatus',
      cellTemplate:
        '<div class="ui-grid-cell-contents">{{ ( row.entity.entityStatus || "" ) | lowercase | humanize}}</a></div>',
      minWidth: widthFactor(100)
    };
    colDefs.lastUpdateDate = {
      field: 'lastUpdateDate',
      type: 'date',
      minWidth: widthFactor(100),
      width: 200,
      sort: { direction: 'desc', priority: 0 },
      sortDirectionCycle: [null, uiGridConstants.DESC, uiGridConstants.ASC]
    };
    colDefs._action = {
      field: '_action',
      displayName: 'Action',
      enableSorting: false,
      cellTemplate:
        '<div class="ui-grid-cell-contents"><a href="{{grid.appScope.action.getUri(row.entity)}}" ng-if="grid.appScope.action.hasValidAction(row.entity)">{{ ( grid.appScope.action.getName(row.entity) || "" ) | lowercase | humanize}}</a></div>',
      minWidth: widthFactor(200)
    };
    colDefs._bulk = {
      field: '_bulk',
      displayName: 'Select',
      enableSorting: false,
      cellTemplate:
        '<div class="ui-grid-cell-contents text-center">' +
        '<a ng-click="grid.appScope.exportIds[row.entity.key] = !grid.appScope.exportIds[row.entity.key]" nohref><i class="fa fa-square-o" ng-class="grid.appScope.exportIds[row.entity.key]?\'fa-check-square-o\':\'fa-square-o\'" aria-hidden="true"></i></a>' +
        '</div>'
    };
    return colDefs;
  }
]);

// gleif
factory('GleifLeisColumnDefs', [
  'SearchLeiAllColumns',
  'DefaultUIGridTooltip',
  '$filter',
  function(SearchLeiAllColumns, DefaultUIGridTooltip, $filter) {
    return [
      Object.assign({}, SearchLeiAllColumns.LEI, { width: 245 }),
      Object.assign({}, SearchLeiAllColumns.legalName),
      Object.assign({}, SearchLeiAllColumns.registeredCountry, { width: 100 }),
      Object.assign({}, SearchLeiAllColumns.registrationStatus, { width: 180 }),
      Object.assign({}, SearchLeiAllColumns.entityStatus, { width: 130 }),
      Object.assign({}, SearchLeiAllColumns.lastUpdateDate, { width: 200 }),
      Object.assign({}, SearchLeiAllColumns._action, { width: 100 }),
      Object.assign({}, SearchLeiAllColumns._bulk, { width: 110 })
    ]
      .map(ensureDisplayName.bind($filter))
      .map(DefaultUIGridTooltip);
  }
]);

// bloomberg lei
factory('BloombergLEIsColumnDefs', [
  'SearchLeiAllColumns',
  'DefaultUIGridTooltip',
  '$filter',
  function(SearchLeiAllColumns, DefaultUIGridTooltip, $filter) {
    return [
      Object.assign({}, SearchLeiAllColumns.LEI, { width: 245 }),
      Object.assign({}, SearchLeiAllColumns.legalName),
      Object.assign({}, SearchLeiAllColumns.legalNameInEnglish),
      Object.assign({}, SearchLeiAllColumns.registeredCountry, { width: 100 }),
      Object.assign({}, SearchLeiAllColumns.registrationStatus, { width: 180 }),
      Object.assign({}, SearchLeiAllColumns.entityStatus, { width: 130 }),
      Object.assign({}, SearchLeiAllColumns.lastUpdateDate, { width: 200 }),
      Object.assign({}, SearchLeiAllColumns._action, { width: 100 }),
      Object.assign({}, SearchLeiAllColumns._bulk, { width: 110 })
    ]
      .map(ensureDisplayName.bind($filter))
      .map(DefaultUIGridTooltip);
  }
]);

factory('DefaultUIGridTooltip', [
  function() {
    return function addTooltip(col) {
      col.cellTooltip = true;
      return col;
    };
  }
]).filter('condense', function() {
  function condense(value) {
    return value > 10 ? Math.round(value).toFixed(0) : (Math.round(value * 10) / 10).toFixed(1);
  }

  return function(value, skip) {
    if (isNaN(+value) || value < 1e3 || skip) return value;
    else if (value >= 1e6) return condense(value / 1e6) + 'M';
    else if (value >= 1e3) return condense(value / 1e3) + 'k';
  };
});

factory('SearchLeiPaginationService', [
  'LeiService',
  'DefaultErrorHandler',
  function(LeiService, DefaultErrorHandler) {
    return {
      getPageFn: function(fields, jsonDataToLEIRowMapper) {
        return function(mode, offset, limit, sortDef, textFilter, numFilter, namedFilters) {
          return LeiService.getFromSource(
            mode,
            undefined,
            true,
            fields,
            offset,
            limit,
            sortDef,
            textFilter,
            numFilter,
            namedFilters,
            true
          )
            .then(function(res) {
              var headers = res.headers ? res.headers() : {};
              var leis = [];
              if (res && res.data && angular.isArray(res.data))
                res.data.forEach(json => {
                  var lei = { key: json.key.lei };
                  jsonDataToLEIRowMapper(json, lei);

                  leis.push(lei);
                });

              return {
                data: leis,
                startOffset: headers['x-start-offset'],
                totalResults: headers['x-total-results']
              };
            })
            .catch(DefaultErrorHandler);
        };
      }
    };
  }
]);

factory('GleifLEIsPaginationService', [
  'GleifLeisColumnDefs',
  'SearchLeiPaginationService',
  'FriendlyIsoTime',
  function(GleifLeisColumnDefs, SearchLeiPaginationService, friendlyIsoTime) {
    var jsonDataToLEIRowMapper = function(bbdsJson, LEIRow) {
      LEIRow.legalName = bbdsJson.data.entity.legalName;
      LEIRow.registeredCountry = bbdsJson.data.entity.legalAddress.country;
      LEIRow.registrationStatus = bbdsJson.data.registration.registrationStatus;
      LEIRow.entityStatus = bbdsJson.data.entity.entityStatus;
      LEIRow.lastUpdateDate = friendlyIsoTime(bbdsJson.data.registration.lastUpdateDate);
    };
    return {
      getPage: SearchLeiPaginationService.getPageFn(
        getFieldsFromColumnDefs(GleifLeisColumnDefs),
        jsonDataToLEIRowMapper
      )
    };
  }
]);

factory('BloombergLEIsPaginationService', [
  'BloombergLEIsColumnDefs',
  'SearchLeiPaginationService',
  'FriendlyIsoTime',
  function(BloombergLEIsColumnDefs, SearchLeiPaginationService, friendlyIsoTime) {
    var jsonDataToLEIRowMapper = function(bbdsJson, LEIRow) {
      LEIRow.legalName = bbdsJson.data.entity.legalName;
      LEIRow.registeredCountry = bbdsJson.data.entity.legalAddressInEnglish.country;
      LEIRow.legalNameInEnglish = bbdsJson.data.entity.legalNameInEnglish;
      LEIRow.registrationStatus = bbdsJson.data.registration.registrationStatus;
      LEIRow.entityStatus = bbdsJson.data.entity.entityStatus;
      LEIRow.lastUpdateDate = friendlyIsoTime(bbdsJson.data.registration.lastUpdateDate);
    };
    return {
      getPage: SearchLeiPaginationService.getPageFn(
        getFieldsFromColumnDefs(BloombergLEIsColumnDefs),
        jsonDataToLEIRowMapper
      )
    };
  }
]);
